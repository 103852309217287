const orderApi = {
    //订单列表
    orderList: '/order/list',
    //订单状态
    orderStatus: '/order/orderStatus',
    //订单来源
    orderSource: '/order/options/source',
    //订单支付方式
    orderPayment: '/order/options/payment',
    //总订单id获取详情
    orderDetail: '/order/getById',
    //根据订单id查看商品简述
    orderFloat: '/order/floatById',
    //取消订单
    orderCancel: '/order/cancel',
    //确认订单
    orderDelivery: '/order/delivery',
    //售后退款列表
    orderRefundList:'/order/refund/list',
    //售后换货列表
    orderReturnList:'/order/return/list',
    //售后列表数量
    orderAfterNum:'/order/after/listNum',
    //售后订单详情
    orderAfterDetail:'/order/after/detail',
    //售后订单驳回
    orderAfterReject:'/order/after/reject',
    //换货申请通过
    oredrAfterReissue:'/order/after/confirm/reissue',
    //退款申请通过
    oredrAfterRefund:'/order/after/confirm/refund',
    //换取总订单id
    oredrGetCombineId:'/order/getCombineId',
    //子订单部分退款
    orderPartRefund:'/order/orderPartRefund',
    //平台支付宝转账到个人接口
    transferFromAlipay:"/capital/transferFromAlipay",
	//子订单分账转至自营店
	transferStoreCashOut:"/order/transferStoreCashOut",
	//逻辑删除总订单
	deleteOrder:"/order/delete"
}


export default orderApi
