<template>
  <div class="container">
    <div class="head">
      <div class="search_box" style="margin-bottom: 20px;">
        <div class="search_main">
          <div class="status">
            <div>申请日期</div>
            <div>
              <el-date-picker
                v-model="datatime"
                type="daterange"
                range-separator="至"
                value-format="yyyy-MM-dd 00:00:00"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </div>
          </div>
        </div>
        <div class="search_main">
          <div class="status">
            <div>用户账号</div>
            <div>
              <el-input
                placeholder="用户账号"
                v-model="queryinfo.mobile"
              ></el-input>
            </div>
          </div>
        </div>
        <div class="search_main">
          <div class="status">
            <div>售后编号</div>
            <div>
              <el-input
                v-model="queryinfo.afterNo"
                placeholder="请输入售后编号"
              ></el-input>
            </div>
          </div>
        </div>
        <div class="search_main">
          <div class="status1" style="display: flex">
            <div class="demandBtn" @click="search">
              <img src="../../../assets/images/search.png" alt="" /> 查询
            </div>
            <div class="resetBtn" @click="reset">
              <img src="../../../assets/images/reset.png" alt="" />重置
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="body">
      <div class="text item">
        <!-- 查询 -->
        <div class="tabs">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="全部订单" name="first"></el-tab-pane>
            <el-tab-pane label="待处理" name="second"></el-tab-pane>
            <el-tab-pane label="已处理" name="third"></el-tab-pane>
          </el-tabs>
        </div>
        <el-table :data="tableData" :header-cell-style="tableHeaderColor" :cell-style="{ color: '#2C2C2C', fontSize: '14px' }" style="width: 100%;font-family: PingFangRoutine;">
          <el-table-column prop="afterNo" label="换货编号" width="200">
          </el-table-column>
          <el-table-column prop="orderNo" label="关联订单号" width="200">
          </el-table-column>
          <el-table-column
            prop="addressInfo.mobile"
            label="用户账号"
            width="180"
          >
          </el-table-column>
          <el-table-column prop="createTime" label="下单时间" width="200">
          </el-table-column>
          <el-table-column prop="afterReason" label="换货原因">
          </el-table-column>
          <el-table-column label="售后状态">
            <template slot-scope="scope">
              <div v-if="scope.row.afterStatus === 0" style="color: red;">{{ "待处理" }}</div>
              <div v-if="scope.row.afterStatus === 1" style="color: red;">{{ "待处理" }}</div>
              <div v-if="scope.row.afterStatus === 2">{{ "已处理" }}</div>
              <div v-if="scope.row.afterStatus === 3">{{ "已取消" }}</div>
              <div v-if="scope.row.afterStatus === 4">{{ "已处理" }}</div>
              <div v-if="scope.row.afterStatus === 5">{{ "已处理" }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="操作">
            <template #default="scope">
              <div class="operating">
                <div class="examine" @click="examine(scope.row)">
                  <i class="iconfont icon-chakan2" style="font-size: 18px;"></i>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div style="height: 10px;"></div>
    <div class="footer">
    <div class="Pagination">
      <div class="Pagination_amin">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page="1"
          :page-size="10"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
      <div class="Pagination_btn" style="height: 28px;">确定</div>
    </div>
    </div>
  </div>
</template>

<script>
import { orderReturnList } from "@/api/order";
import "../../../font-style/font.css";
export default {
  data() {
    return {
      queryinfo: {
        afterNo: "", //售后编号
        afterStatus: ["-1"], //售后状态
        currPage: "1", //当前页
        mobile: "", //用户账号
        startTime: "", //开始时间
        endTime: "", //结束时间
      },
      datatime: [], //时间
      total: null, //总条数
      activeName: "first", //tabs切换
      tableData: [], //表格数据
    };
  },
  activated() {
    this.list();
  },
  methods: {
    tableHeaderColor({ rowIndex }) {
      if (rowIndex === 0) {
        return "background-color: rgba(246, 248, 249, 1);color: #333333;fontSize: '14px'";
      }
    },
    // 售后列表
    list() {
     orderReturnList(this.queryinfo).then((res) => {
        this.total = res.data.total * 1;
        this.tableData = res.data.list;
      });
    },
    // 查询
    search() {
      this.queryinfo.startTime = this.datatime[0];
      this.queryinfo.endTime = this.datatime[1];
      this.queryinfo.currPage = ""
      this.total = 0
      this.list();
    },
    //重置
    reset() {
      this.datatime=""
      this.queryinfo.mobile=""
      this.queryinfo.afterNo=""
      this.queryinfo.currPage="1"
      this.queryinfo.endTime=""
      this.queryinfo.startTime=""
      this.list()
    },
    // tabs切换点击
    handleClick(val) {
      if (val.index == 0) {
        this.queryinfo.afterStatus = [];
        this.queryinfo.afterStatus.push("-1");
      } else if (val.index == 1) {
        this.queryinfo.afterStatus = [];
        this.queryinfo.afterStatus.push("0");
        this.queryinfo.afterStatus.push("1");
      } else {
        this.queryinfo.afterStatus = [];
        this.queryinfo.afterStatus.push("2");
        this.queryinfo.afterStatus.push("4");
      }
      this.list()
    },
    // 分页
    handleCurrentChange(val) {
      this.queryinfo.currPage = val;
      this.list();
    },
    // 查看详情
    examine(val) {
      this.$router.push({
        path:'/order/return/detail',
        query:{
          id: val.id,
          orderNo:val.orderNo
          }
      })
    },
  },
};
</script>

<style lang='less' scoped>
@import "../../../assets/css/common.less";
 .head{
  margin-bottom: 10px;
  background-color: white;
  padding-left: 20px;
  box-sizing: border-box;
  padding-top: 8px;
  padding-bottom: 0px;
  border-radius: 5px;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
}
/deep/ .tabs {
  margin-left: 20px;
  margin-top: 30px;
}
/deep/ .el-table th.el-table__cell > .cell {
  text-align: center;
  padding-right: 0px;
}
/deep/ .el-table__cell {
  text-align: center;
}
.body{
  padding-top: 0px;
}
</style>
